let requireAll = (req) => {
  return req.keys().map(req);
};
console.log('webpack running');

/**
 * icons
 */
//requireAll(require.context('./icons/', true, /\.svg$/));

/**
 * bootstrap-icons
 */
// import 'bootstrap-icons/icons/gem.svg';
// import 'bootstrap-icons/icons/sun.svg';
// import 'bootstrap-icons/icons/moon.svg';

/**
 * twig
 */
// RegEx: load only Twig-files NOT starting with an underscore (since those are partials)
requireAll(require.context('./twig', true, /(?:(?:(?:[/]?)(?:[^/]+\/)+)([^_]+\.twig))/));

/**
 * JS
 */
import './js/bootstrap.js';
import './js/swiper.js';
import './js/navigation.js';
import './js/main.js';
import '../node_modules/swiper/swiper-bundle.min.js';

/**
 * SCSS
 */
import './scss/main.scss';


