import Swiper, { Navigation,Pagination } from 'swiper';

Swiper.use([Navigation, Pagination]);

var placedSwipers = document.querySelectorAll('.news-slider-swiper');
var enabledSwipers = [];

for (var i = 0; i < placedSwipers.length; i++) {
    enabledSwipers[i] = new Swiper('#' + placedSwipers[i].getAttribute('id'), {
        slidesPerView: 1.2,
        spaceBetween: 16,
        loop: true,
        loopFillGroupWithBlank: true,
        navigation: {
            nextEl: '#' + placedSwipers[i].getAttribute('id') + '-next',
            prevEl: '#' + placedSwipers[i].getAttribute('id') + '-prev',
        },
        breakpoints: {
            768: {
                slidesPerView: 2,
                spaceBetween: 24,
                slidesPerGroup: 2
            },
            992: {
                slidesPerView: 3,
                spaceBetween: 24,
                slidesPerGroup: 3
            }
        }
    });
}


const imageSwiperDetail = new Swiper(".car-detail-image-swiper", {
    loop: false,

    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
    on: {
        realIndexChange: function () {
            let index = this.realIndex + 1; /* slide 1 => slides[1] */
            document.getElementById('car-detail-selected-image').innerText = index;
        },
    }
});


const imageSwiperList = new Swiper(".car-list-image-swiper", {
    loop: false,

    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    }
});