
var contact_buttons = document.querySelectorAll('[data-action="initiate-contact"]');
for (let i = 0; i < contact_buttons.length; i++) {
    contact_buttons[i].addEventListener('click', function(){
        document.getElementById('modal-iframe-zoho').showModal();
    });
}

var contact_buttons1 = document.querySelectorAll('.initiate-contact');
for (let i = 0; i < contact_buttons1.length; i++) {
    contact_buttons1[i].addEventListener('click', function(){
        document.getElementById('modal-iframe-zoho').showModal();
    });
}